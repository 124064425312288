<script setup lang="ts">
import type { Group } from '#ui/types'
import type { PropType } from 'vue'

const props = defineProps({
	modelValue: {
		type: null as unknown as PropType<any[] | any | undefined>,
		required: true
	},
	items: {
		type: Array,
		default: () => ([])
	},
	url: {
		type: String,
		default: undefined
	},
	searchField: {
		type: String,
		default: 'filter[title]'
	},
	query: {
		type: Object,
		default: () => ({})
	},
	sort: {
		type: String,
		default: 'title'
	},
	fieldLabel: {
		type: String,
		default: 'title'
	},
	fieldValue: {
		type: String,
		default: 'id'
	},
	multiple: {
		type: Boolean,
		default: true
	},
	placeholder: {
		type: String,
		default: undefined
	},
	searchable: {
		type: Boolean,
		default: false
	},
	searchablePlaceholder: {
		type: String,
		default: undefined
	},
	allowEmpty: {
		type: Boolean,
		default: false
	},
	limit: {
		type: Number,
		default: 50
	},
	ui: {
		type: Object,
		default: () => ({})
	}
})

const emits = defineEmits([
	'update:modelValue'
])

const { t } = useI18n({
	useScope: 'local'
})

const localValue = computed({
	get: () => props.modelValue,
	set: (value) => emits('update:modelValue', value)
})

const localUi = { ...{
	wrapper: (props.url ? 'divide-y' : 'divide-none'),
	input: {
		size: 'text-sm',
		height: 'h-10',
	}
}, ...props.ui }

const group = {
	key: 'default',
	commands: props.items,
} as Group

const el = ref()
const q = ref(' ')

const isMultiple = computed(() => Array.isArray(localValue.value))

if (props.url) {
	group.search = async (q: string) => {
		const params = {...props.query, ...{
			[props.searchField]: q,
			'filter[status]': 'enabled',
			sort: props.sort,
			perPage: 100
		}} as any
		
		const result = await useAPIFetch<{ data: [] }>(props.url!, {
			query: params
		})
		
		const data = []
		if (props.allowEmpty && !isMultiple.value) {
			data.push({
				id: undefined,
				title: t('not_select')
			})
		}
		
		return [ ...data, ...result.data ]
	}
}

const onChange = (val: any) => {
	if (props.allowEmpty && !Array.isArray(localValue.value)) {
		localValue.value = localValue.value?.id === val?.id ? undefined : val
	}
}

onMounted(async () => {
	await el.value.updateQuery(' ')
	el.value.updateQuery('')
})
</script>

<template>
	<UCommandPalette
		ref="el"
		v-model="localValue"
		:query="q"
		:placeholder="searchablePlaceholder"
		:searchable-placeholder="searchablePlaceholder"
		:command-attribute="fieldLabel"
		:empty-state="{icon: '', queryLabel: '', label: t('loading')}"
		:by="fieldValue"
		:multiple="multiple"
		:searchable="!!url || searchable"
		:autoselect="true"
		:autoclear="false"
		:groups="[group]"
		:fuse="{
			resultLimit: limit
		}"
		:ui="localUi"
		@change="onChange"
	>
		<template #default-inactive="{ command }">
			<UBadge
				v-if="command.disabled"
				color="orange"
				size="xs"
				class="opacity-50"
			>
				{{ t('soon') }}
			</UBadge>
		</template>
		<template #empty-state>
			<div class="flex flex-col items-center justify-center flex-1 px-6 py-14 sm:px-14">
				<p class="text-sm text-center text-gray-900 dark:text-white">
					{{ query.length ? t('loading') : t('empty') }}
				</p>
			</div>
		</template>
	</UCommandPalette>
</template>

<i18n lang="json">
{
	"ru": {
		"soon": "Скоро",
		"loading": "Загрузка...",
		"empty": "Список пуст",
		"not_select": "Без выбора"
	}
}
</i18n>